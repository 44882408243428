#formulario{
    background-color: rgb(0, 161, 242);
    width: 60%;
    margin: auto;
    border-radius: 20px;
    box-shadow: 1px 1px 1px gray;
    padding: 20px;
}
@media (max-width: 600px){
    #formulario{
        width: 90%;
    }
}