
@import '../../props' ;

.navbar
{
	.container
	{
		.collapse
		{
			.navbar-nav
			{
				.nav-item
				{
					.nav-link
					{
						color: black ;
						font-weight: bolder;
						font-size: 15px;
					}
				}
				.link
				{
					.nav-link
					{
						position: relative;
						&::before
						{
							position: absolute;
							content: '' ;
							bottom: 0 ;
							left: 0 ;
							width: 0 ;
							height: 3px ;
							background-color: white ;
							border-radius: 30px ;
							transition: all ease 0.2s;
						}
						&:hover
						{
							color: white;
							&::before
							{
								width: 100% ;
							}
						}
					}
				}
				.icon
				{
					a
					{
						transition: 0.3s;
						&:hover
						{
							color: white ;
						}
					}
				}
			}
		}
	}
}