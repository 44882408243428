@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");
.active {
  padding: 5px;
  background-color: #05a081; }

.products .container .title h2 {
  font-weight: bolder;
  color: #003228; }
.products .container .row .c .card:hover {
  transition: 0.3s;
  background-color: #f2f3f4; }
  .products .container .row .c .card:hover .card-body {
    transform: scale(1.05); }
.products .container .row .c .card .card-header .product-name {
  font-weight: bolder; }
.products .container .row .c .card .card-header .stars {
  font-size: 15px; }
.products .container .row .c .card .card-body {
  transition: 0.3s; }
.products .container .row .c .card .card-footer .pricing .price .first-price {
  color: red; }
.products .container .row .c .card .card-footer .pricing .price .second-price {
  font-weight: bolder; }

  section {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  section video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  section .navigation {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  section .navigation li {
    list-style: none;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 4px;
    background: #fff;
    padding: 3px 3px 0;
    opacity: 0.7;
    transition: 0.5s;
  }
  
  section .navigation li:hover {
    opacity: 1;
  }
  
  section .navigation li img {
    width: 120px;
    transition: 0.5s;
  }
  
  section .navigation li img:hover {
    width: 200px;
  }

  
  .app__video {
    height: auto;
    position: relative;
  }
  .videos{
    
  }
  .app__video video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .app__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.65);
  }
  
  .app__video-overlay_circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
  }
  
