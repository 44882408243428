@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");
.active {
  padding: 5px;
  background-color: #0099df; }
.contactoWhatsapp{
  background-color: #0099df;
  text-align: center;
  width: 40%;
  height: 25px;
  border-radius: 10px;
}
.contactoWhatsapp a{
  text-decoration: none;
  color: aliceblue;
}
.contact{
  bottom: auto;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  box-shadow: 1px 1px 1px gray;
}

.contact .title h2 {
  font-weight: bolder;
  color: #0099df; }
.contact .row .c {
  font-weight: bolder;
  font-size: 18px; }
  .contact .row .c .left-side div p {
    border-left: 3px solid #0099df; }
    .contact .row .c .left-side div p i {
      color: #0099df; }
  
.linkis{
  text-decoration:none;
  color: black;
}

