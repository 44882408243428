
#btn-up
{
	z-index: 9999 ;
	top: 90% ;
	left: 2% ;
	transition: 0.3s ;
	&:hover
	{
		transform: scale(1.05);
	}
}