

@import '../../props' ;

.faq
{
	.container
	{
		.title
		{
			h2
			{
				@include title() ;
			}
		}
		.accordion
		{
			.accordion-item
			{
				.accordion-button
				{
					font-weight: bolder;
					color: black ;
					background-color: white ;
				}
			}
		}
	}
}