@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");
header {
  background-image: url("../../images/covers/cover.jpg");
  background-size: cover;
  background-position: center; }
  header .img-overlay {
    background-color: rgba(0, 0, 0, 0.8); }
  header .content {
    color: white; }
    header .content .brand-name {
      font-family:cursive;
      font-size: 40px;
      letter-spacing: 2px; }
      header .content .brand-name span {
        color: #4000ff; }
    header .content .brand-description {
      font-size: 20px; }
    header .content .empty-line {
      height: 3px;
      background-color:  #4000ff;
      border-radius: 30px; }
.btn{
  border-color: #4000ff;
  color: #4000ff;
}
